
  /*
  *  loadStore
  *
  *  Load all stores of the json file
  *
  *  @since 26/11/2015
  *  @param	n/a
  *  @return	success: var stores
  *  @return  error: XXXX
  */

  var MapStyle, map_store;

  var loadStore = function(){

      var singlemap = $('#single-gmap'),
      coords = singlemap.data('coords'),
      markerIcon = singlemap.data('marker');
      MapStyle = [{"featureType":"administrative","elementType":"labels.text.fill","stylers":[{"color":"#444444"}]},{"featureType":"landscape","elementType":"all","stylers":[{"color":"#f2f2f2"}]},{"featureType":"poi","elementType":"all","stylers":[{"visibility":"off"}]},{"featureType":"road","elementType":"all","stylers":[{"saturation":-100},{"lightness":45}]},{"featureType":"road.highway","elementType":"all","stylers":[{"visibility":"simplified"}]},{"featureType":"road.arterial","elementType":"labels.icon","stylers":[{"visibility":"off"}]},{"featureType":"transit","elementType":"all","stylers":[{"visibility":"off"}]},{"featureType":"water","elementType":"all","stylers":[{"color":"#46bcec"},{"visibility":"on"}]}];


    createSingleMap();

    createSingleMarker(coords,markerIcon);

  }



  /*
  *  createMap
  *
  *  Create the Google Map Object
  *
  *  @since 26/11/2015
  *  @param	n/a
  *  @return n/a
  */

  var createSingleMap = function(){
    map_store = new google.maps.Map(document.getElementById('single-gmap'), {
      center: {lat: 0, lng: 0},
      zoom: 14,
      styles: MapStyle,
      disableDefaultUI: true
    });
  }



  /*
  *  createMarker
  *
  *  Create the Google Map Marker Object
  *
  *  @since 26/11/2015
  *  @param	object marker
  *  @return n/a
  */

  var createSingleMarker = function(marker,markerIcon){

    var lat = parseFloat(marker.lat);
    var lng = parseFloat(marker.lng);

    //generate marker
    var marker = new google.maps.Marker({
      position: new google.maps.LatLng(lat,lng),
      map: map_store,
      icon: new google.maps.MarkerImage(markerIcon, null, null, null, new google.maps.Size(28,37))
    });
    marker.setAnimation(google.maps.Animation.DROP);

    map_store.setCenter(marker.getPosition());

  }
