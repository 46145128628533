

  //faq-medium-holder

  var firstload = true;


  if( Foundation.MediaQuery.current === 'medium'){
    $('.faq').appendTo('#faq-medium-holder');
  };


  $(window).on('changed.zf.mediaquery', function(event, newSize, oldSize) {

    if(firstload) {
      newSize = Foundation.MediaQuery.current;
      firstload = false;
    }

    if( newSize === 'medium') {

      $('.faq').appendTo('#faq-medium-holder');

    } else {

      $('.faq').appendTo('#faq-large-holder');

    }


  });
