
  if( $('#first-opened-faq-overlay').length > 0 ) {
    var first_trigger = true;
  } else {
    var first_trigger = false;
  }

  $('.faq-question').click(function(e){
    e.preventDefault();

    var self = $(this);
    var container = self.parent();

    if( ! self.hasClass('opened') ) {

      $('.faq-question').removeClass('opened');
      $('.faq-answer').slideUp();

      self.toggleClass('opened');
      container.find('.faq-answer').slideToggle(400,function(){

        if( first_trigger ) {

          var pos = container.position();
          var width = container.outerWidth();
          var height = container.outerHeight();
          var clone = container.clone();

          $('html, body').animate({ scrollTop: (container.offset().top - 25)}, 400);

          clone.addClass('first-opened-faq').appendTo('.faq');
          clone.find('.faq-answer').slideDown();

          clone.css({
            'top' : pos.top,
            'left' : pos.left,
            'width' : width,
            'height' : height
          });

          first_trigger = false;
        }


      });

      if (history.replaceState) {
        history.replaceState( {} , self.attr('title'), self.attr('href') );
      }


    }



  });



  $('.faq-question[href="'+window.location.href+'"]').trigger('click');



  // hide overlay
  $(document).on('click','#first-opened-faq-overlay',function(){

    $(this).fadeOut(250);
    $('.first-opened-faq').fadeOut(250,function(){
      $('.first-opened-faq').remove();
    });
    first_trigger = false;

  });

  //disable link klick on cloned element
  $(document).on('click','.first-opened-faq a',function(e){

    e.preventDefault();
    return false;

  });

